import React from "react"
import PropTypes from "prop-types"
// Utilities
import kebabCase from "lodash/kebabCase"
// Components
import { Link, graphql } from "gatsby"
import Layout from "../components/Layout"
import SEONOINDEX from "../components/seonoindex"
import { SlugLink } from '../components/utils'

class Category extends React.Component {
  render() {
    const { data, location, pageContext } = this.props;
    console.log("category", location);
    // if (location != null) {
    //   if (location.href === 'https://www.thaiislamic.com/category/') {
    //     window.location.href = 'https://www.thaiislamic.com/category';
    //   }
    // }
    return (
      <Layout location={location} title={data.title}>
        <SEONOINDEX
          title={`All Category`}
          description={`All Category - ${data.domainName}`}
        />
        <div className="w-grid__columns w-grid__columns--one">
          <div>
            <h1>Category Page</h1>
            {/* <h2>{location.href}</h2> */}
            <ul>
              {data.privateGraphql.categories.map(tag => (
                <li key={tag.id}>
                  <Link to={`/${SlugLink(kebabCase(tag.slugname))}`}>
                    {tag.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </Layout>
    )
  }
}

export default Category
export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        domainName
      }
    }
    privateGraphql {
        categories(limit:100,skip:0,siteId: 1084) {
          id
          name
          slugname
        }
      }
  }
`